import http from '@/utils/http'

export function getMembers(params){
  return http('/members', {params})
}

export function getMembersDetail(id){
  return http(`/members/${id}`)
}

export function delMembers(id){
  return http.delete(`/members/${id}`)
}