/**
 * 导出excel
 * @param {*} personData 导出数据
 * @param {*} title 导出文件名
 * @param {*} tHeader excel标题  ['名字']
 * @param {*} filterVal 标题对应字段  ['name']
 * @param {*} callBack 回调 - 处理加载
 */
export default function exportToExcel(personData, title, tHeader, filterVal, callBack) {
  //excel数据导出
  require.ensure([], () => {
    const {
      export_json_to_excel
    } = require("@/utils/Export2Excel.js");

    const data = formatJson(filterVal, personData);
    export_json_to_excel(tHeader, data, title);
    setTimeout(() => {
      callBack()
    }, 500)
  });
}
function formatJson(filterVal, jsonData) { 
    return jsonData.map(v => filterVal.map(j => v[j]));
}