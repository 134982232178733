<template>
  <div class="app-members">
    <Module-Header title="App Members">
      <el-button class="export" @click="exportAppMembers"><img src="@/assets/images/link.png" />Export</el-button>
    </Module-Header>

    <div class="search-box">
      <el-input placeholder="Keywords(e.g. member ID, contact no. or email)" 
        prefix-icon="el-icon-search" v-model="listQ.like_keyword"></el-input>
      
      <Date-picker label="Date of Joining" :joiningDate="listQ.join_at" @updateDate="changeDate"></Date-picker>

      <el-button class="search" icon="el-icon-search" @click="getData"> Search </el-button>
    </div>

    <div class="table-box">
      <el-table :data="tableData" ref="table" style="width:100%;">
        <el-table-column v-for="(its, idx) in tableCate" :key="idx"
          :prop="its.prop" :label="its.label" :min-width="its.width" sortable> </el-table-column>
        <el-table-column width="130" align="center">
          <template slot-scope="scope">
            <div class="editor">
              <img src="@/assets/images/edit.png" @click="toEditPage(scope.row.id)" />
              <img src="@/assets/images/delete.png" @click="del(scope.row.id)"/>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :autoScroll='false'
        :page.sync="listQ._start"
        :limit.sync="listQ._limit"
        layout="prev, pager, next"
        :pager-count="count"
        @pagination="getData"
      />
    </div>

  </div>
</template>

<script>
import { getMembers, delMembers } from '@/api/appMembers'
import exportToExcel from "@/utils/exportExcel";

export default {
  data() {
    return {
      Keywords: '',
      tableCate: [
        {label: 'Member ID', prop: 'id', width: '170'},
        {label: 'Phone No.', prop: 'contactNumber', width: '160'},
        {label: 'Email', prop: 'email', width: '180'},
        {label: 'Current Stamps', prop: 'curStamps', width: '190'},
        {label: 'Stamps Ever Earned', prop: 'allStamps', width: '220'},
        {label: 'Date of Joining', prop: 'joinAt', width: '220'},
      ],
      tableData: [],
      count: 5,
      total: 0,
      listQ:{
        like_keyword: '',
        join_at: '',
        _start: 1,
        _limit: 20,
      },
    }
  },
  methods: {
    exportAppMembers() {
      let data = this.tableData,
      tHeader = [],
      filterVal = [],
      title = 'App Members'

      this.tableCate.filter(its => {
        tHeader.push(its.label)
        filterVal.push(its.prop)
      })
      exportToExcel(data, title, tHeader, filterVal, () => {})
    },
    changeDate(s) {
      this.listQ.join_at = s ? new Date(s).format('yyyy-MM-dd') : ''
    },
    getData() {
      let {listQ} = this, form = {
        like_keyword: listQ.like_keyword,
        join_at: listQ.join_at,
        _start: listQ._start - 1,
        _limit: listQ._limit,
      }
      
      getMembers(form).then(v => {
        v.data.filter(its => {
          its.joinAt = new Date(its.joinAt).format('yyyy/MM/dd hh:mm')
          its.curStamps = 0
          its.allStamps = 0
          its.stampTransactions.filter(item => {
            if(item.stampOperationType == 0) {
              its.curStamps += item.stamps
              its.allStamps += item.stamps
            } else its.curStamps -= item.stamps
          })
        })
        this.tableData = v.data
        this.total = Number(v.headers['x-total-count']) 
      })
    },
    toEditPage(id) {
      this.$router.push({ path:`/appMembers/edit/${id}`})
    },
    del(id) {
      this.$popUp.config({
        content: 'This action cannot be undone.',
        confirm: {
          e: () => {
            delMembers(id).finally(() => {
              this.getData()
              this.$popUp.removePopup()
            })
          }
        },
      })
    }
  },
  mounted() {

    this.getData()
  }
}
</script>

<style scoped lang="scss">
.app-members {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  .search-box{
    display: flex;
    font-size: 0.875rem;
    width: 100%;
    margin-top: 3.125rem;

    .search {
      margin-left: 1.25rem;
    }
  }
}
</style>